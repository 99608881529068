import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import moment from "moment";

const tableColsCompany = [
  {
    text: "ID",
    value: "id",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    width: 25,
    sort: true
  },
  {
    text: "Город",
    value: "city",
    renderFunction: value =>
      `<div style="display: inline-grid"><span>${value.city_details.name}</span>
                <span style="background-color: #95c23d; border-radius: 15px; color: #fff; padding: 2px 5px">${
                  dictionariesHelper.timezone[value.city_details.timezone]
                }</span></div>`,
    userRole: [70]
    //sort: true
  },
  {
    text: "Название",
    value: "name",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    sort: true
  },

  {
    text: "Получатель платежа",
    value: "acquiring_entity",
    renderFunction: value =>
      `${dictionariesHelper.acquiringEntity[value.acquiring_entity]}`,
    userRole: [10, 20, 30, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Объектов",
    value: "objects_count",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    src: "/img/table_icons/tabbar__icon_3.svg",
    width: 20,
    sort: true
  },
  {
    text: "Пользователей",
    value: "users_count",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    src: "/img/table_icons/tabbar__icon_9.svg",
    width: 20,
    sort: true
  },
  {
    text: "Контроллеров",
    value: "controllers_count",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    src: "/img/table_icons/tabbar__icon_5.svg",
    width: 20,
    sort: true
  },
  {
    text: "Оплаты польз. в этом месяце",
    value: "payments_sum_last_month",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Оплаты польз. в этом году",
    value: "payments_sum_last_year",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Дилер",
    renderFunction: value =>
      `${value.diller_details ? value.diller_details.name : "-"}`,
    userRole: [10, 20, 30, 40, 50, 70],
    value: "diller",
    src: "/img/table_icons/tabbar__icon_1.svg",
    width: 20,
    sort: true
  },
  {
    text: "Дата создания",
    value: "created_at",
    userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
    renderFunction: value => {
      return moment(value.created_at * 1000).format("DD.MM.YYYY HH:mm");
    },
    sort: true
  }
];

export default tableColsCompany;
